import React, {useEffect, useState} from 'react';
import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';

import Calendar from '../../../helpers/class/Calendar';
import Checkers from '../../../helpers/class/Checkers';
import Dialog from '../../../helpers/class/Dialog';
import Popover from '../../../helpers/class/Popover';

import FullWidthBar from './bookingSearchBar/FullWidthBar';
import SliderContainedBar from './bookingSearchBar/SliderContainedBar';
import UnderSliderContainedBar from './bookingSearchBar/UnderSliderContainedBar';
import {useDispatch, useSelector} from "react-redux";
import {setAdults, setInfants, setKids} from "../../../redux/actions";

export default function BookingSearchBar ( props ) {

  // useEffect( () => {
  //   console.log("props.bookingSearchbarWithBedrooms", props.bookingSearchbarWithBedrooms, "BookingSearchBar")
  // })

  const updateUri = props.hasOwnProperty('updateUri') ? props.updateUri : true

  Calendar.setProps( props );
  Popover.setProps( props );
  Dialog.setProps( props );

  const calendarMaterialTheme = Calendar.getCalendarMaterialTheme;
  const popoverMaterialTheme = Popover.getPopoverMaterialTheme;
  const dialogMaterialTheme = Dialog.getDialogMaterialTheme;

  //----------------SELECT INIT-------------------
  const options = [
    {
      label: "Destinations",
      options: [
        { label: "Rethymno", value: "Rethymno" },
        { label: "Heraklion", value: "Heraklion" }
      ]
    },
    {
      label: "Properties",
      options: [
        { label: "Rethymno Villa 1", value: "Rethymno Villa 1" },
        { label: "Rethymno Villa 2", value: "Rethymno Villa 2" }
      ]
    },
  ];

  const minBedrooms = useSelector( state => state['appSettings']['minBedrooms']);
  const maxBedrooms = useSelector( state => state['appSettings']['maxBedrooms']);

  const bedroomOptionsHandler = () => {
    const options = [{label: 'All', value: 0}];

    for (let i=minBedrooms; i<=maxBedrooms; i++) {
      if ( i>0 ) {
        options.push({label: i, value: i})
      }
    }

    return options
  }

  const bedroomOptions = bedroomOptionsHandler()

  //----------------SELECT CUSTOM STYLES-------------------
  const customStyles = {
    container: ( provided ) => ({ ...provided,
      paddingTop: '20px', maxWidth: 350 }),
    control: ( provided ) => ({ ...provided,
      minHeight: '32px',
      border: 'none',
      backgroundColor: 'transparent!important',
      borderColor: 'transparent!important',
      boxShadow: 'none!important',
      fontSize: '14px' }),
    valueContainer: ( provided ) => ({ ...provided,
      padding: '0 8px' }),
    menu: ( provided ) => ({ ...provided, zIndex: '1000', borderRadius: props.searchbarRadius != null ? props.searchbarRadius + 'px' : '10px' }),
    menuList: ( provided, state ) => ({ ...provided,
      backgroundColor: state.isFocused ? '#f4f4f4' : state.isSelected ? '#f4f4f4' : state.isActive ? '#f4f4f4' : 'transparent',
      background: state.isFocused ? '#f4f4f4' : state.isSelected ? '#f4f4f4' : state.isActive ? '#f4f4f4' : 'transparent',
      margin: '8px 10px',
      letterSpacing: '0.01em',
      fontSize: '14px',
      fontFamily: '"Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
    }),
    option: ( provided, state ) => ({ ...provided,
      backgroundColor: state.isFocused ? '#f4f4f4' : state.isSelected ? '#f4f4f4' : state.isActive ? '#f4f4f4' : 'transparent',
      background: state.isFocused ? '#f4f4f4' : state.isSelected ? '#f4f4f4' : state.isActive ? '#f4f4f4' : 'transparent',
      color: '#4b4b4b',
      padding: '6px 13px',
      letterSpacing: '0.01em',
      fontFamily: '"Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
    }),
    groupHeading: ( provided ) => ({ ...provided,
      padding: '6px',
      fontWeight: '600',
      fontSize: '11px',
      color: '#000',
      letterSpacing: '0.01em',
      fontFamily: '"Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
    }),
    input: ( provided ) => ({ ...provided,
      margin: 0,
      padding: 0,
      color: props.hasOwnProperty( 'fullWidthBar' ) ? props.searchbarThemeColor != null ? !props.searchbarThemeColor ? '#fff' : '#000' : props.designMode ? '#fff' : '#000' : '#000', }),
    singleValue: ( provided ) => ({ ...provided,
      color: props.hasOwnProperty( 'fullWidthBar' ) ? props.searchbarThemeColor != null ? !props.searchbarThemeColor ? '#fff' : '#000' : props.designMode ? '#fff' : '#000' : '#000' }),
    placeholder: ( provided ) => ({ ...provided,
      color: props.hasOwnProperty( 'fullWidthBar' ) ? props.searchbarThemeColor != null ? !props.searchbarThemeColor ? '#fff' : '#000' : props.designMode ? '#fff' : '#000' : '#000',
      opacity: '0.7' }),
  };

  let textColor = 'text-black';
  let borderColor = 'b-transparent-black';
  let buttonColor = 'b-transparent-black';
  if ( props.searchbarThemeColor != null )
    if ( !props.searchbarThemeColor ) {
      textColor = 'text-white';
      borderColor = 'b-transparent-white';
      buttonColor = 'b-transparent-white';
    } else {
      textColor = 'text-black';
      borderColor = 'b-transparent-black';
      buttonColor = 'b-transparent-black';
    }
  else
  if ( props.designMode ) {
    textColor = 'text-white';
    borderColor = 'b-transparent-white';
    buttonColor = 'b-transparent-white';
  } else {
    textColor = 'text-black';
    borderColor = 'b-transparent-black';
    buttonColor = 'b-transparent-black';
  }


  const [ value, setValue ] = React.useState<DateRange<Date>>([ null, null ]);
  //const setOpen = useState( false );

  //-------------POP OVER----------------
  const [ popover_anchorEl, popover_setAnchorEl ] = React.useState<HTMLButtonElement | null>( null );
  const popover_handleOpen = ( event: React.MouseEvent<HTMLButtonElement> ) => {
    popover_setAnchorEl( event.currentTarget );
    document.getElementById( 'guests_label' ).classList.add( 'fade' );
    document.getElementById( 'guests_label' ).parentElement.classList.add( 'focused' );
  };

  const popover_handleClose = () => {
    document.getElementById( 'guests_label' ).classList.remove( 'fade' );
    document.getElementById( 'guests_label' ).parentElement.classList.remove( 'focused' );
    popover_setAnchorEl( null );
  };
  const popover_open = Boolean( popover_anchorEl );
  const popover_id = popover_open ? 'simple-popover' : undefined;

  const [ adults_amount, adults_setAmount ] = useState( 0 );
  const [ kids_amount, kids_setAmount ] = useState( 0 );
  const [ infants_amount, infants_setAmount ] = useState( 0 );

  const [ adults_decreaseHidden, adults_setDecreaseHidden ] = useState( true );
  const [ kids_decreaseHidden, kids_setDecreaseHidden ] = useState( true );
  const [ infants_decreaseHidden, infants_setDecreaseHidden ] = useState( true );
  const [ adults_increaseHidden, adults_setIncreaseHidden ] = useState( false );
  const [ kids_increaseHidden, kids_setIncreaseHidden ] = useState( false );
  const [ infants_increaseHidden, infants_setIncreaseHidden ] = useState( false );

  const [ guests_text, guests_setText ] = useState( 'Who' );

  const adults_handleIncrease = () => {
    adults_setAmount( adults_amount => adults_amount + 1 );

    if ( ( adults_amount + 1 ) > 0 )
      adults_setDecreaseHidden( false );
    else
      adults_setDecreaseHidden( true );

    if ( ( adults_amount + 1 ) < 30 )
      adults_setIncreaseHidden( false );
    else
      adults_setIncreaseHidden( true );

    guests_setText( () => guestsTextCalc( ( adults_amount+1 ), kids_amount, infants_amount ) )
  };
  const adults_handleDecrease = () => {
    adults_setAmount( adults_amount => adults_amount - 1 );

    if ( ( adults_amount - 1 ) < 1 )
      adults_setDecreaseHidden( true );
    else
    if ( ( kids_amount > 0 || infants_amount > 0 ) && ( adults_amount - 1 ) < 2 )
      adults_setDecreaseHidden( true );
    else
      adults_setDecreaseHidden( false );

    if ( ( adults_amount - 1 ) >= 0 )
      adults_setIncreaseHidden( false );
    else
      adults_setIncreaseHidden( true );

    guests_setText( () => guestsTextCalc( ( adults_amount-1 ), kids_amount, infants_amount ) )
  };
  const kids_handleIncrease = () => {
    kids_setAmount( kids_amount => kids_amount + 1 );

    if ( ( kids_amount + 1 ) > 0 )
      kids_setDecreaseHidden( false );
    else
      kids_setDecreaseHidden( true );

    if ( ( kids_amount + 1 ) < 30 )
      kids_setIncreaseHidden( false );
    else
      kids_setIncreaseHidden( true );

    if ( adults_amount == 0 ) {
      adults_setAmount( adults_amount => adults_amount + 1 );
      adults_setDecreaseHidden( true );
      guests_setText( () => guestsTextCalc( ( adults_amount+1 ), ( kids_amount+1 ), infants_amount ) )
    } else {
      guests_setText( () => guestsTextCalc( adults_amount, ( kids_amount+1 ), infants_amount ) )
      if ( adults_amount < 2 )
        adults_setDecreaseHidden( true );
      else
        adults_setDecreaseHidden( false );
    }
  };
  const kids_handleDecrease = () => {
    kids_setAmount( kids_amount => kids_amount - 1 );

    if ( ( kids_amount - 1 ) < 1 )
      kids_setDecreaseHidden( true );
    else
      kids_setDecreaseHidden( false );

    if ( ( kids_amount - 1 ) >= 0 )
      kids_setIncreaseHidden( false );
    else
      kids_setIncreaseHidden( true );

    if ( adults_amount == 1 )
      if ( ( kids_amount - 1 ) < 1 && infants_amount < 1 )
        adults_setDecreaseHidden( false );
      else
        adults_setDecreaseHidden( true );
    else
      adults_setDecreaseHidden( false );

    guests_setText( () => guestsTextCalc( adults_amount, ( kids_amount-1 ), infants_amount ) )
  };
  const infants_handleIncrease = () => {
    infants_setAmount( infants_amount => infants_amount + 1 );

    if ( ( infants_amount + 1 ) > 0 )
      infants_setDecreaseHidden( false );
    else
      infants_setDecreaseHidden( true );

    if ( ( infants_amount + 1 ) < 30 )
      infants_setIncreaseHidden( false );
    else
      infants_setIncreaseHidden( true );

    if ( adults_amount == 0 ) {
      adults_setAmount( prevState => prevState + 1 );
      adults_setDecreaseHidden( true );
      guests_setText( () => guestsTextCalc( ( adults_amount+1 ), kids_amount, ( infants_amount+1 ) ) )
    } else {
      guests_setText( () => guestsTextCalc( adults_amount, kids_amount, ( infants_amount+1 ) ) )

      if ( adults_amount < 2 )
        adults_setDecreaseHidden( true );
      else
        adults_setDecreaseHidden( false );
    }
  };
  const infants_handleDecrease = () => {
    infants_setAmount( infants_amount => ( infants_amount - 1 ) );

    if ( ( infants_amount - 1 ) < 1 )
      infants_setDecreaseHidden( true );
    else
      infants_setDecreaseHidden( false );

    if ( ( infants_amount - 1 ) >= 0 )
      infants_setIncreaseHidden( false );
    else
      infants_setIncreaseHidden( true );

    if ( adults_amount == 1 )
      if ( kids_amount < 1 && ( infants_amount - 1 ) < 1 )
        adults_setDecreaseHidden( false );
      else
        adults_setDecreaseHidden( true );
    else
      adults_setDecreaseHidden( false );

    guests_setText( () => guestsTextCalc( adults_amount, kids_amount, ( infants_amount-1 ) ) )
  };


  //-------------DIALOG-------------
  const [ dialog_open, dialog_setOpen ] = React.useState( false );
  // const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const dialog_handleOpen = () => {
    dialog_setOpen( true );
  };

  const dialog_handleClose = ( value: string ) => {
    dialog_setOpen( false );
  };

  const dispatch = useDispatch()

  const setGuestsAmountZero = () => {

    dispatch( setAdults(0) )
    dispatch( setKids(0) )
    dispatch( setInfants(0) )

    adults_setAmount( 0 );
    kids_setAmount( 0 );
    infants_setAmount( 0 );

    adults_setDecreaseHidden( true );
    kids_setDecreaseHidden( true );
    infants_setDecreaseHidden( true );

    guests_setText( () => guestsTextCalc( 0, 0, 0 ) );

    dialog_handleClose( 'close' );
  }

  const focusToggle = ( type ) => {
    if ( document !== undefined ) {
      const checkinById = document.getElementById( 'form-group-default-checkin' );
      const checkoutById = document.getElementById( 'form-group-default-checkout' );

      const checkin = document.querySelector( '.form-group-default-checkin' );
      const checkinLabel = document.querySelector( '#checkin_label' );
      const checkout = document.querySelector( '.form-group-default-checkout' );
      const checkoutLabel = document.querySelector( '#checkout_label' );

      switch ( type ) {
      case 'close_all':
        checkin.classList.remove( 'focused' );
        checkinLabel.classList.remove( 'fade' );
        checkout.classList.remove( 'focused' );
        checkoutLabel.classList.remove( 'fade' );
        checkinById.blur();
        checkoutById.blur();
        break;
      case 'close_checkin':
        checkin.classList.remove( 'focused' );
        checkinLabel.classList.remove( 'fade' );
        checkout.classList.add( 'focused' );
        checkoutLabel.classList.add( 'fade' );
        checkinById.blur();
        checkoutById.focus();
        break;
      case 'close_checkout':
        checkin.classList.add( 'focused' );
        checkinLabel.classList.add( 'fade' );
        checkout.classList.remove( 'focused' );
        checkoutLabel.classList.remove( 'fade' );
        checkinById.focus();
        checkoutById.blur();
        break;
      }
    }
  }

  const disableWeekends = ( date ) => {
    return date.getDay() === 0 || date.getDay() === 6;
  }
  const disableAllDatesAfterTwoYears = ( date ) => {
    let value = null;
    if ( Checkers.objChildExists( props, 'hasOpenCalendar' ) && !Checkers.booleanState( props.hasOpenCalendar ) ) {
      const thisDate = new Date();
      const disableAfter = new Date( thisDate.getFullYear() + 2, thisDate.getMonth(), thisDate.getDate() );
      value = new Date( date.getFullYear(), date.getMonth(), date.getDate() ) >= disableAfter;
    }

    return value;
  }


  const popover = {
    //-----POP_OVER Variables, Handlers-------
    adults: {
      handleIncrease: adults_handleIncrease,
      handleDecrease: adults_handleDecrease,
      decreaseHidden: adults_decreaseHidden,
      increaseHidden: adults_increaseHidden,
      setDecreaseHidden: adults_setDecreaseHidden,
      setIncreaseHidden: adults_setIncreaseHidden,
      setAmount: adults_setAmount,
      amount: adults_amount
    },
    kids: {
      handleIncrease: kids_handleIncrease,
      handleDecrease: kids_handleDecrease,
      decreaseHidden: kids_decreaseHidden,
      increaseHidden: kids_increaseHidden,
      setDecreaseHidden: kids_setDecreaseHidden,
      setIncreaseHidden: kids_setIncreaseHidden,
      setAmount: kids_setAmount,
      amount: kids_amount,
    },
    infants: {
      handleIncrease: infants_handleIncrease,
      handleDecrease: infants_handleDecrease,
      decreaseHidden: infants_decreaseHidden,
      increaseHidden: infants_increaseHidden,
      setDecreaseHidden: infants_setDecreaseHidden,
      setIncreaseHidden: infants_setIncreaseHidden,
      setAmount: infants_setAmount,
      amount: infants_amount,

    },
    setGuestsAmountZero: setGuestsAmountZero,
    guestsText: guests_text,
    guestsSetText: guests_setText,
    guestsTextCalc: guestsTextCalc,
    customTheme: popoverMaterialTheme,
    handleOpen: popover_handleOpen,
    handleClose: popover_handleClose,
    anchorEl: popover_anchorEl,
    open: popover_open,
    id: popover_id
  };

  const dialog = {
    handleClose: dialog_handleClose,
    handleOpen: dialog_handleOpen,
    setOpen: dialog_setOpen,
    open: dialog_open,
    customTheme: dialogMaterialTheme
  };

  const calendar = {
    value: value,
    setValue: setValue,
    customTheme: calendarMaterialTheme,
    focusToggle: focusToggle,
    disableWeekends: disableWeekends,
    disableAllDatesAfterTwoYears: disableAllDatesAfterTwoYears
  };

  const settings = {
    textColor: textColor,
    buttonColor: buttonColor,
    borderColor: borderColor,
    isManager: props.hasOwnProperty( 'isManager' ) ? props.isManager != null ? props.isManager : false : false,
    searchbarRadius: props.hasOwnProperty( 'searchbarRadius' ) ? props.searchbarRadius != null ? props.searchbarRadius : null : null,
    searchbarShadow: props.hasOwnProperty( 'searchbarShadow' ) ? props.searchbarShadow != null ? props.searchbarShadow : null : null,
    bookingSearchbarWithBedrooms: props.hasOwnProperty( 'bookingSearchbarWithBedrooms' ) ? props.bookingSearchbarWithBedrooms != null ? props.bookingSearchbarWithBedrooms : null : null,
    position: props.hasOwnProperty( 'position' ) ? props.position != null ? props.position : null : null,
    fixed: props.hasOwnProperty( 'position' ) ? !props.position ? 'fixed-bottom' : '' : ''
  };

  return (
    <>
      {
        props.hasOwnProperty( 'sliderSearchBar' ) ?
          props.sliderSearchBar ?
            <SliderContainedBar
              padding={Checkers.objChildExists( props, 'padding' ) ? props.padding : null}
              containerClass={Checkers.objChildExists( props, 'containerClass' ) ? props.containerClass : null}
              isManager={settings.isManager}
              textColor={settings.textColor}
              searchbarRadius={settings.searchbarRadius}
              searchbarShadow={settings.searchbarShadow}
              noShadow={Checkers.objChildExists( props, 'noShadow' ) && props.noShadow}
              bookingSearchbarWithBedrooms={settings.bookingSearchbarWithBedrooms}
              sliderSearchBarFullWidth={Checkers.objChildExists( props, 'sliderSearchBarFullWidth' ) ? props.sliderSearchBarFullWidth : false}
              //---SELECT----
              options={options}
              bedroomOptions={bedroomOptions}
              customStyles={customStyles}
              //---CALENDAR----
              calendar={calendar}
              //-----POP_OVER-------
              popover={popover}
              // DIALOG
              dialog={dialog}
              isHomePage={props.isHomepage}
              isHotel={props.isHotel}
              updateUri={updateUri}
            />
            :''
          : props.hasOwnProperty( 'underSliderContained' ) ?
            props.underSliderContained ?
              <UnderSliderContainedBar
                isManager={settings.isManager}
                textColor={settings.textColor}
                searchbarRadius={settings.searchbarRadius}
                searchbarShadow={settings.searchbarShadow}
                bookingSearchbarWithBedrooms={settings.bookingSearchbarWithBedrooms}
                //---SELECT----
                options={options}
                bedroomOptions={bedroomOptions}
                customStyles={customStyles}
                //---CALENDAR----
                calendar={calendar}
                //-----POP_OVER-------
                popover={popover}
                // DIALOG
                dialog={dialog}
                updateUri={updateUri}
              />
              : ''
            : props.hasOwnProperty( 'fullWidthBar' ) ?
              props.fullWidthBar ?
                <FullWidthBar
                  isManager={settings.isManager}
                  textColor={settings.textColor}
                  buttonColor={settings.buttonColor}
                  borderColor={settings.borderColor}
                  position={settings.position}
                  bookingSearchbarWithBedrooms={settings.bookingSearchbarWithBedrooms}
                  fixed={settings.fixed}
                  //---SELECT----
                  options={options}
                  bedroomOptions={bedroomOptions}
                  customStyles={customStyles}
                  //---CALENDAR----
                  calendar={calendar}
                  //-----POP_OVER-------
                  popover={popover}
                  // DIALOG
                  dialog={dialog}
                  updateUri={updateUri}
                />
                : ''
              : ''
      }
    </>
  )
}

function guestsTextCalc ( adults_amount, kids_amount, infants_amount ) {
  const guests_sum =  adults_amount + kids_amount;
  if ( guests_sum > 1 && infants_amount > 1 )
    return guests_sum + ' guests, ' + infants_amount + ' infants'
  else if ( guests_sum > 1 && infants_amount == 1 )
    return guests_sum + ' guests, 1 infant'
  else if ( guests_sum == 1 && infants_amount > 1 )
    return '1 guest, ' + infants_amount + ' infants'
  else if ( guests_sum == 1 && infants_amount == 1 )
    return '1 guest, 1 infant'
  else if ( guests_sum > 1 )
    return guests_sum + ' guests'
  else if ( guests_sum == 1 )
    return '1 guest'
  else
    return 'Who will stay'
}
