import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { faSearch, faSpinner } from '@fortawesome/pro-regular-svg-icons'
import
{
  faBed as farBed,
  faCalendar as farCalendar,
  faMapMarkerAlt as farMapMarkerAlt,
  faUserFriends as farUserFriends,
    faXmark as faXmark
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dialog from '@mui/material/Dialog';
import Popover from '@mui/material/Popover';
import { ThemeProvider } from '@mui/material/styles';
import axios from 'axios';

import { getSearchDefaultOptionsAction, loadSearchOptionsAction } from '../../../../api/client';
import Checkers from '../../../../helpers/class/Checkers';
import {
  addDestination,
  fetchPropertiesAction, removeAllDestination, setAdults,
  setApplyParams, setBedrooms, setDateRange, setDestination, setInfants, setKids,
  setLoadingProperties,
  setOffset, setPageNumber, setSearchSpinner, setSearchText
} from '../../../../redux/actions';
import * as appContext from '../../../AppContext';

import CalendarCheckin from './partials/CalendarCheckin';
import CalendarCheckout from './partials/CalendarCheckout';
import Guests from './partials/Guests';
import {useRouter} from "next/router";
import {fetchBuilderSettingByKey, getBedroomValue, updateBedrooms} from "../../../../helpers/MethodHelper";
import {getDefaultOptions} from "./FullWidthBar";
import {DateRange} from "@mui/lab/DateRangePicker";
import dayjs from "dayjs";

export default function UnderSliderContainedBar ( props ) {

  const updateUri = props.hasOwnProperty('updateUri') ? props.updateUri : true

  const dispatch = useDispatch()

  const router = useRouter()

  const currentDestination = useSelector(state => state['appSettings']['currentDestination'])

  const propertiesParams = useSelector(state => state['propertiesParams']);

  const customSearch = useSelector(state => state['propertiesParams']['search']);

  const pageId = useSelector(state => state['propertiesParams']['page_id']);

  const destinations = useSelector(state => state['propertiesParams']['destinations']);

  const isListingPage = useSelector(state => state['propertiesParams']['isListingPage']);

  const isSearchPage = useSelector(state => state['propertiesParams']['isSearchPage']);

  const [selectedLocation, setSelectedLocation] = useState(null);

  const bookingBarRef = useRef();

  const [bookingBarDiv, setBookingBarDiv] = useState(null);

  const searchBtnLabel = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_properties_search_btn_label')
  );

  const searchSpinner = useSelector(state => state['appSettings']['searchSpinner']);

  const searchSelectLabel = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_properties_select_label')
  );

  const [removeAllDestinations, setRemoveAllDestinations] = useState(false)

  const [localSearchText, setLocalSearchText] = useState(null)

  const [localDestinationId, setLocalDestinationId] = useState(null)

  const checkin = useSelector(state => state['propertiesParams']['checkin'])

  const checkout = useSelector(state => state['propertiesParams']['checkout'])

  const [localDateRange, setLocalDateRange] = useState<DateRange<Date>>([null, null]);

  const bedrooms = useSelector(state => state['propertiesParams']['bedrooms']);

  const [localBedrooms, setLocalBedrooms] = useState(bedrooms)

  const [localAdultsNum, setLocalAdultsNum] = useState(0)

  const [localKidsNum, setLocalKidsNum] = useState(0)

  const [localInfantsNum, setLocalInfantsNum] = useState(0)

  useEffect(() => {
    setLocalAdultsNum(propertiesParams.adults)
    setLocalKidsNum(propertiesParams.kids)
    setLocalInfantsNum(propertiesParams.infants)
  }, [propertiesParams]);

  useEffect(() => {
    let checkin_unix = new Date(checkin).valueOf() ? new Date(checkin) : null
    let checkout_unix = new Date(checkout).valueOf() ? new Date(checkout) : null

    setLocalDateRange([checkin_unix, checkout_unix])
  }, [checkin, checkout, propertiesParams])

  useEffect(() => {
    setLocalBedrooms(bedrooms)
  }, [bedrooms, propertiesParams])

  const dateRangeHandler = (val: DateRange<Date>) => {
    props.calendar.setValue(val)
    props.calendar.focusToggle('close_checkin');
    setLocalDateRange(val)
  }

  const handleInputChange = (input) => {
    let location_val = input.value;
    let location_data = location_val.split("_");
    let location_type = location_data[0];
    let location_id = location_data[1];

    if ( location_type == 'all' ) {
      setSelectedLocation(input);
      setRemoveAllDestinations(true)
      setLocalSearchText('')
    } else if ( location_type == 'destination') {
      setSelectedLocation(input);
      setRemoveAllDestinations(false)
      setLocalDestinationId(location_id)
      setLocalSearchText('')
    } else if ( location_type != 'property') {
      setSelectedLocation(input);
      setRemoveAllDestinations(true)
      setLocalSearchText(input.label)
    } else {
      let customOption = {
        value: input.label,
        label: input.label
      }

      setSelectedLocation(customOption)
      setRemoveAllDestinations(true)
      setLocalSearchText(input.label)
    }
  };

  const dispatchValues = async () => {
    if (removeAllDestinations) {
      dispatch(removeAllDestination())
    } else if (localDestinationId != null) {
      dispatch(setDestination(localDestinationId))
    }

    dispatch( setSearchText( localSearchText ))

    dispatch(setDateRange(localDateRange))

    dispatch(setBedrooms(localBedrooms))

    dispatch( setAdults(localAdultsNum))

    dispatch( setKids(localKidsNum))

    dispatch( setInfants(localInfantsNum))

    return true
  }

  const searchHandler = async (event) => {
    await dispatchValues()

    dispatch( setOffset( 0 ) )
    dispatch( setPageNumber( 1 ) )

    propertiesParams.offset = 0
    propertiesParams.adults = localAdultsNum
    propertiesParams.kids = localKidsNum
    propertiesParams.infants = localInfantsNum
    propertiesParams.bedrooms = localBedrooms
    propertiesParams.search = localSearchText

    if (removeAllDestinations) {
      propertiesParams.destinations = []
    } else if (localDestinationId!=null) {
      propertiesParams.destinations = [localDestinationId]
    }

    if (localDateRange[0]!=null) {
      propertiesParams.checkin = dayjs(localDateRange[0]).format('YYYY-MM-DD')
    } else {
      propertiesParams.checkin = null
    }

    if (localDateRange[1]!=null) {
      propertiesParams.checkout = dayjs(localDateRange[1]).format('YYYY-MM-DD')
    } else {
      propertiesParams.checkout = null
    }

    if ( selectedLocation==null && !isSearchPage ) {
      let queryString = Checkers.serialize( propertiesParams );
      router.push( "/properties/search/?" + queryString );
      event.preventDefault()
      return;
    }

    let location_id = ''
    let location_val = ''
    if (selectedLocation != null) {
      location_val = selectedLocation.value;
      let location_data = location_val.split("_");
      let location_type = location_data[0];
      location_id = location_data[1];
    }

    dispatch(setSearchSpinner(true))

    if (isListingPage && currentDestination == propertiesParams.destinations[0] && Checkers.isValidPositiveNumber(location_id)) {
      dispatch(setLoadingProperties(true));
      dispatch<any>(fetchPropertiesAction(propertiesParams))
      dispatch(setApplyParams(1))
    } else if (isSearchPage) {
      dispatch(setLoadingProperties(true));
      dispatch<any>(fetchPropertiesAction(propertiesParams))
      dispatch(setApplyParams(1))

      let queryString = Checkers.serialize(propertiesParams);
      propertiesParams.search = location_val
      const newUrl = "/properties/search/?" + queryString
      if (updateUri) {
        window.history.replaceState({...window.history.state, as: newUrl, url: newUrl}, '', newUrl)
      }
    } else {
      let queryString = Checkers.serialize(propertiesParams);

      propertiesParams.search = location_val
      router.push("/properties/search/?" + queryString);
    }
    event.preventDefault()
  };

  useEffect(() => {
    let popoverText = [];

    let adults = localAdultsNum + localKidsNum
    if (adults > 0) {
      popoverText.push(adults + " adults")
    }

    let infants = localInfantsNum
    if (infants > 0) {
      popoverText.push(infants + " infants")
    }

    if (popoverText.length > 0) {
      props.popover.guestsSetText(popoverText.join(", "));
    } else {
      props.popover.guestsSetText("Who will stay");
    }
  }, [localAdultsNum, localKidsNum, localInfantsNum])

  const loadOptions = (inputValue, callback) => {
    loadSearchOptionsAction(pageId, inputValue, callback)
  }

  const [defaults, setDefaults] = useState(null);
  useEffect(() => {
    getDefaultOptions(pageId)
        .then(response => {

          setDefaults(response.options);
          let destination = Checkers.isValidArray(destinations) ? destinations[0] : null

          let destinationFlag = false
          Checkers.isValidArray(response.options) && response.options.map((item) => {
            if (item.value == 'destination_' + destination) {
              setSelectedLocation(item)
              destinationFlag = true
            }
          })

          if (Checkers.isValidString(customSearch) && !destinationFlag) {
            let customOption = {
              value: customSearch,
              label: customSearch
            }
            setSelectedLocation(customOption)
          } else if (!destinationFlag) {
            let customOption = {
              value: 'all_properties',
              label: 'All properties'
            }
            setSelectedLocation(customOption)
          }
        });
  }, [destinations, propertiesParams]);

  let searchbarRadius = '10px';
  if (props.searchbarRadius != null) {
    searchbarRadius = props.searchbarRadius + 'px'
  }

  const setNextElementPaddingTop = (nextElem, nextElemPaddingTop, bookingBarHeight) => {
    let paddingTop = 0;
    paddingTop = parseInt(String(bookingBarHeight)) / 2 + parseInt(nextElemPaddingTop);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // nextElem.style.setProperty( 'padding-top', `${paddingTop}px`, 'important' );
  };

  useEffect(() => {
    if (window !== undefined) {
      let bookingBarHeight = 0;
      setBookingBarDiv(bookingBarRef?.current)
      if (bookingBarDiv) {
        bookingBarHeight = bookingBarDiv?.getBoundingClientRect().height;
        let nextElem = bookingBarDiv.offsetParent.offsetParent.nextSibling;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        while (nextElem.style === undefined) {
          nextElem = nextElem.nextSibling;
        }

        const nextElemPaddingTop = window.getComputedStyle(nextElem as Element, null).getPropertyValue('padding-top').split('px')[0];
        setNextElementPaddingTop(nextElem, nextElemPaddingTop, bookingBarHeight);
        window.addEventListener('resize', function () {
          bookingBarHeight = bookingBarDiv?.getBoundingClientRect().height;
          setNextElementPaddingTop(nextElem, nextElemPaddingTop, bookingBarHeight);
        });
      }
    }
  }, [bookingBarDiv]);

  return (
      <div ref={bookingBarRef} className="row justify-content-center w-100 no-margin">
        <div className="col-12 col-lg-12 col-xl-10">
          <form id="frm_book" onSubmit={event => searchHandler(event)} className="needs-validation">
            <div className="row bg-white padding-20"
                 style={{
                   borderRadius: props.searchbarRadius != null ? props.searchbarRadius + 'px' : '10px',
                   boxShadow: props.searchbarShadow != null ? '0px 0px ' + props.searchbarShadow + 'px #CCC' : '0px 0px 8px #CCC',
                   zIndex: '10'
                 }}>
              {
                props.isManager == true ?
                    <div className="col-12 col-md-4 col-lg-4 h-100 ps-xs-0 pe-xs-0 ps-sm-0 pe-sm-0 pb-xs-2">
                      <div
                          className="form-group form-group-default form-group-default-select2 no-margin no-border no-arrow overflow-visible">
                        <label htmlFor="location_property" className="form-label bold mb-0">
                          <FontAwesomeIcon
                              icon={farMapMarkerAlt}/> {Checkers.isValidString(searchSelectLabel) ? searchSelectLabel : 'Location/Property'}
                        </label>
                        <AsyncSelect
                            noOptionsMessage={() => 'nothing found'}
                            loadingMessage={() => 'searching...'}
                            cacheOptions={false}
                            defaultOptions={defaults}
                            loadOptions={loadOptions}
                            onChange={handleInputChange}
                            value={selectedLocation}
                            className="SearchBar-Form__form-group-select"
                            id="location_property"
                            name="location_property"
                            inputId="location_property"
                            instanceId="location_property"
                            isSearchable
                            options={props.options}
                            styles={props.customStyles}
                            placeholder={'Where are you going'}
                            blurInputOnSelect
                            menuPlacement={'bottom'}
                            components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                        />
                      </div>
                    </div>
                    : ''
              }
              <div
                  className={props.isManager == true ? 'col-12 col-md-5 col-lg-5 h-100 ps-xs-0 pe-xs-0 ps-sm-0 pe-sm-0 pb-xs-2' : 'col-12 col-md-8 col-lg-8 h-100 ps-xs-0 pe-xs-0 ps-sm-0 pe-sm-0 pb-xs-2'}>
                <div className="row form-group-attached no-margin no-border">
                  <div className="col-6 ps-xs-0 pe-xs-0 ps-sm-0 pe-sm-0">
                    <div
                        id="form-group-default-checkin"
                        className="form-group form-group-default form-group-default-checkin no-border no-margin">
                      <label id="checkin_label" className="form-label bold">
                        <FontAwesomeIcon icon={farCalendar}/> Check in
                      </label>
                      {
                          props.hasOwnProperty('calendar') &&
                          <CalendarCheckin
                              dateRange={localDateRange}
                              setDateRange={dateRangeHandler}
                              customTheme={props.calendar.customTheme}
                              focusToggle={props.calendar.focusToggle}
                              disableAllDatesAfterTwoYears={props.calendar.disableAllDatesAfterTwoYears}
                          />
                      }
                    </div>
                  </div>
                  <div className="col-6 ps-xs-0 pe-xs-0 ps-sm-0 pe-sm-0">
                    <div
                        id="form-group-default-checkout"
                        className="form-group form-group-default form-group-default-checkout no-border no-margin">
                      <label id="checkout_label" className="form-label bold">
                        <FontAwesomeIcon icon={farCalendar}/> Check out
                      </label>
                      {
                          props.hasOwnProperty('calendar') &&
                          <CalendarCheckout
                              dateRange={localDateRange}
                              setDateRange={dateRangeHandler}
                              customTheme={props.calendar.customTheme}
                              focusToggle={props.calendar.focusToggle}
                              disableAllDatesAfterTwoYears={props.calendar.disableAllDatesAfterTwoYears}
                          />
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div
                  className={props.isManager == true ? 'col-12 col-md-3 col-lg-3 ps-xs-0 pe-xs-0 ps-sm-0 pe-sm-0' : 'col-12 col-md-4 col-lg-4 ps-xs-0 pe-xs-0 ps-sm-0 pe-sm-0'}>
                <div
                    className={props.bookingSearchbarWithBedrooms ? 'overflow-visible form-group form-group-default form-group-default-select2 input-group-attached no-border no-margin overflow-visible' : 'overflow-visible form-group form-group-default input-group-attached no-border no-margin'}>
                  {
                    props.bookingSearchbarWithBedrooms ?

                        <>
                          <label htmlFor="bedrooms" className={'form-label\ ' + props.textColor}>
                            <FontAwesomeIcon icon={farBed}/> Bedrooms</label>
                          <Select
                              name="bedrooms"
                              value={getBedroomValue(bedrooms, props.bedroomOptions)}
                              inputId="bedrooms"
                              isSearchable
                              options={props.bedroomOptions}
                              styles={props.customStyles}
                              onChange={(newValue) => {
                                updateBedrooms(newValue, dispatch)
                              }}
                              blurInputOnSelect
                              components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                          />
                        </>
                        :
                        props.hasOwnProperty('popover') ?
                            <>
                              <label id="guests_label" className="form-label bold">
                                <FontAwesomeIcon icon={farUserFriends}/> Guests
                              </label>
                              <p className={'w-100 fs-14 fw-normal no-margin text-master text-truncate opacity-70 hidden-xs hidden-sm'}
                                 onClick={props.popover.handleOpen}
                                 aria-describedby={props.popover.id}
                                 style={{cursor: 'pointer'}}
                              >
                                {props.popover.guestsText}
                              </p>
                              <p
                                  className={'w-100 fw-normal text-master text-truncate fs-14 no-margin opacity-70 visible-xs visible-sm visible-md'}
                                  onClick={props.dialog.handleOpen}
                                  style={{cursor: 'pointer'}}
                              >
                                {props.popover.guestsText}
                              </p>
                              <ThemeProvider theme={props.popover.customTheme}>
                                <Popover
                                    className="hidden-xs hidden-sm"
                                    id={props.popover.id}
                                    open={props.popover.open}
                                    anchorEl={props.popover.anchorEl}
                                    onClose={props.popover.handleClose}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'left',
                                    }}
                                >
                                  <Guests popover={props.popover}
                                          dialog={props.dialog}
                                          mobile={false}
                                          dispatchValues={false}
                                          adultsNum={localAdultsNum}
                                          kidsNum={localKidsNum}
                                          infantsNum={localInfantsNum}
                                          setAdultsNum={setLocalAdultsNum}
                                          setKidsNum={setLocalKidsNum}
                                          setInfantsNum={setLocalInfantsNum}
                                  />
                                </Popover>
                              </ThemeProvider>
                              <Dialog onClose={props.dialog.handleClose} open={props.dialog.open}>
                                <Guests popover={props.popover}
                                        dialog={props.dialog}
                                        mobile={true}
                                        dispatchValues={false}
                                        adultsNum={localAdultsNum}
                                        kidsNum={localKidsNum}
                                        infantsNum={localInfantsNum}
                                        setAdultsNum={setLocalAdultsNum}
                                        setKidsNum={setLocalKidsNum}
                                        setInfantsNum={setLocalInfantsNum}
                                />
                              </Dialog>
                            </>
                            : ''
                  }
                  <span className="input-group-btn" style={{right: '0!important'}}>
                  <button form="frm_book"
                          className={'btn btn-primary btn-cons btn-design-color btn-search-color h-100 hidden-xs hidden-sm visible-md visible-lg visible-xl visible-xxl\ ' + props.textColor}
                          style={{
                            minWidth: '52px!important',
                            minHeight: '50px!important',
                            borderRadius: searchbarRadius
                          }}
                          type="submit"
                          aria-label="search"
                  >
                    {
                      !searchSpinner ?
                          <FontAwesomeIcon icon={faSearch}/>
                          :
                          <FontAwesomeIcon icon={faSpinner} spin/>
                    }
                  </button>
                </span>
                </div>
              </div>

              <div
                  className="col-12 col-md-2 h-100 ps-xs-0 pe-xs-0 ps-sm-0 pe-sm-0 mt-2 hidden-md hidden-lg hidden-xl hidden-xxl">
                <button form="frm_book"
                        className={'btn btn-primary btn-cons btn-design-color btn-search-color h-100 w-100\ ' + props.textColor}
                        style={{minHeight: '52px!important', borderRadius: searchbarRadius}}
                        aria-label="Search"
                        type="submit">
                  {
                    !searchSpinner ?
                        <span>
                          <FontAwesomeIcon icon={faSearch}
                                           className="m-r-5"/> {Checkers.isValidString(searchBtnLabel) ? searchBtnLabel : 'Search'}
                        </span>
                        :
                        <span>
                          <FontAwesomeIcon icon={faSpinner} spin className={'m-r-5'}/> Searching
                        </span>
                  }
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
  )
}
